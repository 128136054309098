import React from "react";
import styled from "styled-components";
import { ReceitaCardProps } from "./IAgenteData";
import iconapproved from "../../Assets/iconapproved.svg";
import AgenteChart from "./AgenteChart";

const InfoContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  background-color: white;

  &:first-child {
    border: 1px solid #ececec;
    padding: 20px;
  }
`;

const ApprovedArea = styled.div`
  position: relative;
  right: 10px;

  span {
    color: #212322;
    font-weight: 300;
    margin-right: 1rem;
  }
`;

const AdjustArea = styled.div`
  position: relative;
  display: flex;
  gap: 2rem;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const AgentHeader = styled.div`
  font-size: 30px;
  color: #212322;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
`;

const InfoArea = styled.div`
  margin-top: 0.5em;
  gap: 2rem;
  display: flex;

  .gray-text {
    color: #7e7e82;
    margin-bottom: 3px;
  }
`;

const InfoLine = styled.div`
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
`;

const Email = styled.div``;
const DividerLine = styled.hr``;

const ReceitaCard = ({ agentData, cnpjData }: ReceitaCardProps) => {
  function formatCNPJ(cnpj: string) {
    // Remove qualquer caractere que não seja número
    cnpj = cnpj.replace(/\D/g, "");

    // Aplica a formatação com pontos e hífen
    return cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      "$1.$2.$3/$4-$5"
    );
  }

  function formatCEP(cnpj: string) {
    // Remove qualquer caractere que não seja número
    cnpj = cnpj.replace(/\D/g, "");

    // Aplica a formatação com pontos e hífen
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})$/, "$1.$2-$3");
  }

  function formatDate(date: string) {
    return new Date(date).toLocaleDateString("pt-BR");
  }

  if (!agentData || !cnpjData) return null;

  return (
    <>
      <InfoContainer style={{ display: "flex" }}>
        <div style={{ width: "68%" }}>
          <Email>{cnpjData?.email}</Email>

          <InfoLine>
            <span style={{ color: "#7E7E82" }}>
              {formatCNPJ(agentData?.cnpj)}
            </span>
          </InfoLine>
          <InfoArea>
            <InfoSection>
              <InfoLine>
                <span className="gray-text">TIPO</span>
                <span>{cnpjData?.tipo}</span>
              </InfoLine>
              <InfoLine>
                <span style={{ marginTop: "2rem" }} className="gray-text">
                  QUADRO SOCIETÁRIO
                </span>
                {cnpjData?.qs?.map((s) => {
                  return <InfoLine>{s.nome}</InfoLine>;
                })}
              </InfoLine>
            </InfoSection>
            <AdjustArea>
              <InfoLine>
                <span className="gray-text">MUNICÍPIO</span>{" "}
                {cnpjData?.municipio} - {cnpjData?.uf}
              </InfoLine>
              <InfoLine>
                <span className="gray-text">CEP</span>{" "}
                {formatCEP(cnpjData?.cep)}
              </InfoLine>
              <InfoLine>
                <span className="gray-text">ABERTURA</span>{" "}
                {formatDate(cnpjData?.abertura)}
              </InfoLine>
            </AdjustArea>
          </InfoArea>
        </div>
        <ApprovedArea>
          <span>Aprovado</span>
          <img width={62} src={iconapproved} alt="Selo aprovado" />
        </ApprovedArea>
      </InfoContainer>
    </>
  );
};
export default ReceitaCard;
