import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Main from "./pages/Home/index";
import Courses from "./pages/Courses/index";
import Autoproducao from "./pages/Autoproducao/index";
import MercadoLivre from "./pages/MercadoLivre/index";
import Register from "./pages/Register/index";
import Simulacao from "./pages/Simulacao/index";
import ConsumoInput from "./pages/ConsumoInput/index";
import Login from "./pages/LoginPage/index";
import MarketPlace from "./pages/MarketPlace/index";

import { useState, useEffect } from "react";

import Parceiro from "./pages/Parceiro/index";
import Perfil from "./pages/Perfil/index";
import { Spinner } from "react-bootstrap";

import CadastrarDadosMes from "./pages/CadastrarDadosMes/index";
import CadFilesMes from "./pages/CadFilesMes/index";

import NotFound from "./pages/NotFound/index";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { isAuthenticated } from "./Services/auth";

import "./App.css";
import CompanyDashboard from "./pages/CompanyDashboard";

import AgentSearch from "./pages/agentSearch.tsx";
import AgentPage from "./pages/agentPage.tsx";
import ClientDashboard from "./pages/ClientDashboard";
import Parceiros from "./pages/Parceiros/index";
import PolicyPrivacyPage from "./pages/PolicyPrivacyPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import PerfilPage from "./pages/PerfilPage";
import FortlevSolar from "./pages/FortlevSolar/index";
import RecuperarSenha from "./pages/RecuperarSenha/index";
import AreaAdm from "./pages/AreaAdm";

import { useAuth } from "./utils/AuthContext";
import { erroToast } from "./utils/toast";

const PrivateRoute = ({
  component: Component,
  requiredPermission,
  requiredRole,
  ...rest
}) => {
  const { user, loading, hasPermission, hasAccess } = useAuth();

  if (loading)
    return (
      <div
        style={{
          marginTop: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          erroToast("Você precisa estar logado para acessar essa página");
          return <Redirect to="/" />;
        }

        const hasRoleAccess =
          requiredRole?.some((role) => hasAccess(role)) || false;
        const hasPermissionAccess =
          requiredPermission?.some((permission) => hasPermission(permission)) ||
          false;

        if (hasRoleAccess || hasPermissionAccess) {
          return <Component {...props} />;
        } else {
          erroToast("Você não tem permissão para acessar essa página");
          return <Redirect to="/" />;
        }
      }}
    />
  );
};
const AntiPrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Main} />
      <Route path="/autoproducao" component={Autoproducao} />
      <Route path="/mercado-livre" component={MercadoLivre} />

      <PrivateRoute
        path="/pesquisa-mercado-livre"
        requiredPermission={["prime_black", "research"]}
        component={AgentSearch}
      />
      <PrivateRoute
        path="/agente/:agentId"
        requiredPermission={["prime_black", "research"]}
        component={AgentPage}
      />
      <PrivateRoute
        path="/simulacao"
        requiredPermission={["prime_black", "simulator"]}
        component={Simulacao}
      />
      <PrivateRoute
        path="/client"
        requiredRole={["CLIENTE"]}
        component={ClientDashboard}
      />
      <PrivateRoute
        path="/parceiro"
        requiredPermission={["prime_black", "prime_basic"]}
        requiredRole={["PARCEIRO"]}
        component={Parceiro}
      />
      <PrivateRoute
        path="/empresa"
        requiredPermission={["ADMIN", "SUPER_ADMIN"]}
        requiredRole={["PARCEIRO"]}
        component={CompanyDashboard}
      />
      <PrivateRoute
        path="/areaAdm"
        requiredPermission={["ADMIN", "SUPER_ADMIN"]}
        component={AreaAdm}
      />

      <Route path="/terms-of-use" component={TermsOfUsePage} />
      <Route path="/policy-privacy" component={PolicyPrivacyPage} />
      <Route path="/fortlev-solar/autoprodutor" component={FortlevSolar} />
      <AntiPrivateRoute path="/login" component={Login} />
      <AntiPrivateRoute path="/recuperarSenha" component={RecuperarSenha} />
      <Route path="*" component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
