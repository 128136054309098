import React from "react";
import styles from "./style.module.css";
import Image from "../../Assets/partnerAdvantage.svg";
import Image1 from "../../Assets/partnerAdvantage1.svg";
import Image2 from "../../Assets/partnerAdvantage2.svg";
import Image3 from "../../Assets/partnerAdvantage3.svg";
import StepImage from "../../Assets/imageStep.svg";
import StepImage1 from "../../Assets/imageStep1.svg";
import StepImage2 from "../../Assets/imageStep2.svg";
import StepImage3 from "../../Assets/imageStep3.svg";
export default function PartnerAdvantages() {
  const advantages = [
    {
      image: Image,
      alt: "Imagem de um cofrinho",
      title: "Comissão Recorrente",
      text:
        "Ganhe mais com nosso plano de comissão recorrente. Receba recompensas mensais pelo sucesso das suas vendas.",
    },
    {
      image: Image1,
      alt: "Imagem de um computador e um celular",
      title: "Acesso ao simulador",
      text:
        "Descubra quanto pode economizar com nosso simulador exclusivo. Tenha insights precisos e otimize seus investimentos.",
    },
    {
      image: Image2,
      alt: "Imagem de uma lampada",
      title: "Mentoria de Vendas",
      text:
        "Receba orientações exclusivas. Melhore suas estratégias de vendas com insights de um líder de mercado.",
    },
    {
      image: Image3,
      alt: "Imagem de um chapéu de formatura",
      title: "Curso de Vendas",
      text:
        "Aprimore suas habilidades de vendas com nosso curso especializado. Domine as melhores práticas para vender usinas.",
    },
  ];
  const steps = [
    {
      title: "Indique",
      content:
        "Indique investidores para usinas, consumidores que desejam economizar no Mercado Livre de Energia ou empresas que querem mudar de comercializadora.",
      image: StepImage,
    },
    {
      title: "Aguarde nosso contato",
      content: `Em breve, nossa equipe entrará em contato para dar continuidade ao atendimento e esclarecer todas as suas dúvidas.`,
      image: StepImage1,
    },
    {
      title: "Solução",
      content:
        "Nosso time comercial está preparado para entender suas necessidades, apresentar as soluções mais adequadas para trabalhar em conjunto para concretizar a proposta",
      image: StepImage2,
    },
    {
      title: "Fechamento",
      content:
        "Após o fechamento do negócio, você será recompensado com uma bonificação exclusiva como forma de valorizar sua parceria conosco!",
      image: StepImage3,
    },
  ];
  return (
    <section className={styles.allContent} id="partner">
      <div className={styles.content}>
        <h2>Merx Prime</h2>
        <p className={styles.advantageP}>
        Conheça nosso programa de parceria
        </p>
        <div className={styles.steps}>
          {steps.map((step, index) => (
            <div key={index} className={styles.step}>
              <div>
                <h4>{step.title}</h4>
                <p>{step.content}</p>
              </div>
              <img src={step.image} alt="Pessoas em uma reunião" />
            </div>
          ))}
        </div>
        <div className={styles.allAdvantages}>
          <div className={styles.partnerAdvantages}>
            <div className={styles.textAdvantages}>
              <h3 className={styles.titleAdvantage}>
                Conheça as vantagens de ser nosso parceiro
              </h3>
              <p className={styles.firstAdvantage}>
                Ao se tornar nosso parceiro, você terá acesso a oportunidades
                exclusivas, suporte especializado e recompensas por indicações.
                Juntos, vamos transformar o setor de energia, promovendo
                economia, eficiência e sustentabilidade.
              </p>
              <p className={styles.secondAdvantage}>
                Venha fazer parte dessa revolução!
              </p>
              <a
                href="https://prime.merxenergia.com.br/"
                target="blank"
                className={styles.knowMore}
              >
                Saiba mais
              </a>
            </div>
            <div className={styles.advantages}>
              {advantages.map((advantage) => [
                <div className={styles.advantage}>
                  <span>
                    {" "}
                    <img src={advantage.image} alt={advantage.alt} />
                  </span>
                  <h3>{advantage.title}</h3>
                  <p>{advantage.text}</p>
                </div>,
              ])}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
