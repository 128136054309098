import axios from "axios";

import {
  getRefreshToken,
  getToken,
  getUserId,
  TOKEN_KEY,
  updateAccessToken,
} from "./auth";
import { erroToast } from "../utils/toast";

const history = require("history").createBrowserHistory();

const faradayApi = axios.create({
  baseURL: "https://faraday.merxenergia.com.br/",
  // baseURL: "https://faraday-merxenergia.up.railway.app/",
  // baseURL: "https://faraday-dev-staging.up.railway.app/",
  // baseURL: "http://localhost:8080/",
});

let isRefreshing = false;
let refreshSubscribers = [];

faradayApi.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const subscribeTokenRefresh = (callback) => {
  refreshSubscribers.push(callback);
};

const onRefreshed = (token) => {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
};

faradayApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log("Erro na requisição", error);
    const originalRequest = error.config;

    if (error.response?.status >= 401 && !originalRequest._retry) {
      console.log("tentando renovar token");
      if (isRefreshing) {
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(faradayApi(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const refreshToken = getRefreshToken();
        const userId = getUserId();

        const newToken = await updateAccessToken(refreshToken, userId);

        // localStorage.setItem(TOKEN_KEY, newToken);

        onRefreshed(newToken);

        originalRequest.headers.Authorization = `Bearer ${newToken}`;

        return faradayApi(originalRequest);
      } catch (refreshError) {
        console.error("Erro ao renovar token", refreshError);
        localStorage.removeItem("user");
        erroToast("Sua sessão expirou, faça login novamente.");
        history.push("/login");
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default faradayApi;
