import React, { useState } from "react";
import calendar from "../../Assets/calendar.svg";
import pencil from "../../Assets/pencil.svg";
import fabioImg from "../../Assets/fabioImg.svg";
import PartnerEditInfo from "../PartnerEditInfo";
import "./style.css";

export default function Main({
  nome,
  sigla,
  email = "",
  cnpj,
  inicioLivre,
  logo,
  parterInfosTotal,
  canEdit = true,
}) {
  const [partEditInfo, setPartEditInfo] = useState(false);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long" };
    const formattedDate = date.toLocaleDateString("pt-BR", options);
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  }

  const handleClick = () => {
    setPartEditInfo(true); // Abre o modal
  };

  const handleClose = () => {
    setPartEditInfo(false); // Fecha o modal
  };

  return (
    <div className="parterInfosContainer">
      {/* Quando clicar na imagem, abrirá o modal */}
      {!logo ? (
        <div className=" skeleton-loader"> </div>
      ) : (
        <div className="parterImage">
          <img src={logo} className=" logoImage" alt="parter" />
        </div>
      )}
      <div className="infosCliente">
        {canEdit && (
          <button onClick={handleClick} className="iconChange">
            <img src={pencil} alt="pencil" />
          </button>
        )}
        <h2 className="nameCompany">{nome}</h2>
        <h3>{sigla}</h3>
        {email && <h3>{email}</h3>}
        {cnpj && <h4>{cnpj}</h4>}
        {inicioLivre && (
          <div className="calendar">
            <img src={calendar} alt="calendar icon" />
            <p>Conosco desde {formatDate(inicioLivre)}</p>
          </div>
        )}
      </div>

      {/* Passa o estado "show" e a função handleClose para o modal */}
      <PartnerEditInfo
        partnerInfo={parterInfosTotal}
        show={partEditInfo} // Controla a exibição do modal
        handleClose={handleClose} // Função para fechar o modal
      />
    </div>
  );
}
