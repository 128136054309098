import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar/index";
import Footer from "../../Components/Footer/index";
import {
  Main,
  ClientNameInfo,
  ClientText,
  StatusMigrationArea,
} from "./styled";

import { LogoArea } from "../ClientDashboard/styled";
import iconpending from "../../Assets/iconpending.png";
import iconchecked from "../../Assets/iconcheck.png";
import styled from "styled-components";
import arrowleft from "../../Assets/arrowleft.png";
import iconapproved from "../../Assets/iconapproved.svg";
import iconClientApproved from "../../Assets/client-approved.png";
import iconClientBankAccount from "../../Assets/client-bank-account.png";
import iconClientBolt from "../../Assets/client-bolt.png";
import iconClientElectricVehicle from "../../Assets/client-electric-vehicle.png";
import iconClientHint from "../../Assets/client-hint.png";
import iconClientSocket from "../../Assets/client-socket.png";
import faradayApi from "../../Services/faradayApi";
import CustoEconomia from "../../Components/Dashboard/CustoEconomia";
import ConsumeLine from "../../Components/ConsumeLine/index";
import { getToken } from "../../Services/auth";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "../Client/style.css";
import "../../Components/CoustCardRow/style.css";
import { formatCNPJ } from "../../utils/utilityFunctions";
import PartnerInfo from "../../Components/ParterInfos/index";
function backPage() {
  window.history.back();
}

const CompanyDashboard = () => {
  const [companyData, setCompanyData] = useState<any>();
  const [companyLogo, setCompanyLogo] = useState("");
  const [profiles, setProfiles] = useState<any[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const affiliateId = localStorage.getItem("affiliateId");
      const companyResponse = await faradayApi.get(`companies/${affiliateId}`);
      if (companyResponse) {
        setCompanyLogo(companyResponse.data.logo);
        setCompanyData(companyResponse.data);
        let measuringPoints = [];
        for (let i = 0; i < companyResponse.data.measuringPoints.length; i++) {
          measuringPoints.push(companyResponse.data.measuringPoints[i]);
        }
        setProfiles(measuringPoints);
      }
    };
    try {
      fetchData();
    } catch (e) {
      console.error(e);
    }
  }, []);

  function convertDate(date: string) {
    return `${new Date(date).toLocaleDateString("pt-br").split(",")[0]}`;
  }

  return (
    <Main>
      {companyData && (
        <>
          <Navbar changeColor="true" />
          <div
            className="container"
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <LogoArea>
              <img
                className="back"
                src={arrowleft}
                alt="Voltar"
                onClick={backPage}
              />
              <div className="back-area" onClick={backPage}>
                <span>Voltar</span>
              </div>
            </LogoArea>
            <ClientText>Cliente indicado</ClientText>
            <PartnerInfo
              cnpj={formatCNPJ(companyData?.cnpj)}
              email={""}
              inicioLivre={companyData?.start_acl}
              logo={companyData?.logo}
              nome={companyData?.name}
              parterInfosTotal={companyData}
              sigla={companyData?.initials}
              canEdit={false}
            />
            {/* <StatusMigrationArea>
              <div className="current-status">
                <div className="migrations-hint">
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        Na fase inicial, os clientes adquirem energia no
                        Ambiente de Contratação Livre (ACL) através da compra
                        direta.
                      </Tooltip>
                    }
                  >
                    <img src={iconClientHint} alt="Ícone" />
                  </OverlayTrigger>
                </div>
                {companyData?.check?.energiaCheck ? (
                  <img src={iconchecked} width={24} alt="approved" />
                ) : (
                  <img src={iconpending} width={24} alt="pending" />
                )}
                <div className="migration-header acl">
                  <span className="step-name">ENERGIA ACL</span>
                </div>
                <img src={iconClientBolt} alt="Ícone" />
                <p className="step-description">Compra de energia</p>
                <div className="bar-divider" />
              </div>

              <div className="current-status">
                {companyData?.check?.distribuidoraCheck ? (
                  <img src={iconchecked} width={24} alt="approved" />
                ) : (
                  <img src={iconpending} width={24} alt="pending" />
                )}
                <div className="migration-header">
                  <div>
                    <div className="migrations-hint">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            A distribuidora realiza ajustes nos contratos e
                            gerencia as transições para garantir a conformidade
                            regulatória.
                          </Tooltip>
                        }
                      >
                        <img src={iconClientHint} alt="Ícone" />
                      </OverlayTrigger>
                    </div>
                    <span className="step-name">DISTRIBUIDORA</span>
                  </div>
                </div>

                <img src={iconClientElectricVehicle} alt="Ícone" />
                <p className="step-description">Ajuste e gestão de contratos</p>
                <div className="bar-divider" />
              </div>

              <div className="current-status">
                {companyData?.check?.financeiroCheck ? (
                  <img src={iconchecked} width={24} alt="approved" />
                ) : (
                  <img src={iconpending} width={24} alt="pending" />
                )}
                <div className="migration-header">
                  <div>
                    <div className="migrations-hint">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Os clientes abrem uma conta específica na Conta
                            Trianon para gerenciar as informações financeiras
                            relacionadas ao consumo de energia.
                          </Tooltip>
                        }
                      >
                        <img src={iconClientHint} alt="Ícone" />
                      </OverlayTrigger>
                    </div>
                    <span className="step-name">INFORMAÇÕES FINANCEIRAS</span>
                  </div>
                </div>

                <img src={iconClientBankAccount} alt="Ícone" />
                <p className="step-description">Abertura da Conta Trianon</p>
                <div className="bar-divider" />
              </div>

              <div className="current-status">
                {companyData?.status === "ATIVO" ? (
                  <img src={iconchecked} width={24} alt="approved" />
                ) : (
                  <img src={iconpending} width={24} alt="pending" />
                )}
                <div className="migration-header">
                  <span className="step-name">CCEE</span>
                  <div className="migrations-hint">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          O processo de migração inclui a submissão e aprovação
                          dos contratos pela Câmara de Comercialização de
                          Energia Elétrica (CCEE).
                        </Tooltip>
                      }
                    >
                      <img src={iconClientHint} alt="Ícone" />
                    </OverlayTrigger>
                  </div>
                </div>
                <img src={iconClientApproved} alt="Ícone" />
                <p className="step-description">
                  Processo de aprovação na CCEE
                </p>
                <div className="bar-divider" />
              </div>

              <div className="current-status">
                {companyData?.check?.medicaoCheck ? (
                  <img src={iconchecked} width={24} alt="approved" />
                ) : (
                  <img src={iconpending} width={24} alt="pending" />
                )}
                <div className="migration-header">
                  <div>
                    <div className="migrations-hint">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            O processo de migração inclui a submissão e
                            aprovação dos contratos pela Câmara de
                            Comercialização de Energia Elétrica (CCEE).
                          </Tooltip>
                        }
                      >
                        <img src={iconClientHint} alt="Ícone" />
                      </OverlayTrigger>
                    </div>
                    <span className="step-name">ADEQUAÇÃO DA MEDIÇÃO</span>
                  </div>
                </div>
                <img src={iconClientSocket} alt="Ícone" />
                <p className="step-description">Ajuste físico da medição</p>
                <div className="bar-divider" />
              </div>

              <div className="current-status">
                {companyData?.check?.aprovacaoCheck ? (
                  <img src={iconchecked} width={24} alt="approved" />
                ) : (
                  <img src={iconpending} width={24} alt="pending" />
                )}
                <span className="step-name"> APROVAÇÃO</span>
                <img src={iconapproved} alt="Ícone" />
                <p className="step-description">Migração completa</p>
              </div>
            </StatusMigrationArea> */}
            {companyData?.status === "ATIVO" ? (
              profiles &&
              profiles.length > 0 && (
                <>
                  {companyData && <CustoEconomia companyData={companyData} />}
                  {profiles && (
                    <ConsumeLine monthlyData={profiles} profiles={profiles} />
                  )}
                </>
              )
            ) : (
              <div className="right-text-center">
                <h1>
                  Histórico e gráficos serão disponibilizados após a migração
                  ser concluída
                </h1>
              </div>
            )}
            {/* {prevData && <ProjecaoConsumo prevData={prevData} />} */}
            {/* {prevData && <XPTO prevData={prevData} />} */}
            {/* {monthlyData && <Historico monthlyData={monthlyData} />} */}
          </div>
          <Footer />
        </>
      )}
    </Main>
  );
};

export default CompanyDashboard;
