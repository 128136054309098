import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar/index";
import Footer from "../../Components/Footer/index";
import { Main, ClientNameInfo, ClientText, ContainerArea } from "./styled";

import faradayApi from "../../Services/faradayApi";
import CustoEconomia from "../../Components/Dashboard/CustoEconomia";
import ConsumeLine from "../../Components/ConsumeLine/index";
import { getToken } from "../../Services/auth";
import { Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "../Client/style.css";
import "../../Components/CoustCardRow/style.css";
import { formatCNPJ } from "../../utils/utilityFunctions";
import { StatusMigrationAreaComponent } from "./StatusMigrationAreaComponent";
import ParterInfos from "../../Components/ParterInfos/index";
import merxLogo from "../../Assets/merx-logo.png";

import { useHistory } from "react-router-dom";

const ClientDashboard = () => {
  const history = useHistory();
  const [companyData, setCompanyData] = useState<any>();
  const [companyCNPJ, setCompanyCNPJ] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [profiles, setProfiles] = useState<any[] | null>(null);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [openPerfilModal, setOpenPerfilModal] = useState(true);
  const [userData, setUserData] = useState<any>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      const id = window.localStorage.getItem("id");
      const userResponse = await faradayApi.get(`users/${id}`);

      setUserData(userResponse?.data);

      const userCompanys = await faradayApi.get(`users/measuring-points/${id}`);

      // setCompanyLogo(userCompanys.data.company.logo);
      setCompanyData(userCompanys.data);

      let measuringPoints = [];
      for (let i = 0; i < userCompanys.data.measuringPoints.length; i++) {
        measuringPoints.push(userCompanys.data.measuringPoints[i]);
      }

      setProfiles(measuringPoints);
    };
    fetchData().catch(console.error);
  }, []);

  function convertDate(date: string) {
    return `${new Date(date).toLocaleDateString("pt-br").split(",")[0]}`;
  }

  const [show, setShow] = useState(false);
  const [name, setName] = useState(userData?.name);
  const [email, setEmail] = useState(userData?.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clearStates = () => {
    setPassword("");
    setConfirmPassword("");
    setEmail("");
    setDisabled(false);
  };

  const saveInfo = async () => {
    setDisabled(true);

    if (password !== confirmPassword) {
      window.alert("As duas senhas precisam ser iguais!");
      clearStates();
      handleClose();
      return;
    }

    const headers = {
      "Content-Type": "application/json",
    };

    const data = {
      password: password ? password : undefined,
      name: name ? name : undefined,
      email: email ? email : undefined,
    };

    try {
      await faradayApi.patch(`users/${userData.id}`, data, {
        headers,
      });

      handleClose();
      clearStates();
      window.alert("Suas novas informações foram salvas com sucesso!");
      window.location.reload();
    } catch (e) {
      window.alert(
        "Erro ao tentar salvar suas informações, por favor, verifique se os campos estão corretos!"
      );
      handleClose();
      clearStates();
    }
  };

  if (!companyData) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
      >
        <ContainerArea>
          <Spinner
            className="text-secondary"
            animation="border"
            variant="secondary"
          />
        </ContainerArea>
      </div>
    );
  }

  return (
    <Main>
      <Navbar changeColor="true" />
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <ClientText>Área do cliente</ClientText>
        {companyData && (
          <>
            <ClientNameInfo>
              <ParterInfos
                nome={userData?.name}
                sigla={""}
                email={userData?.email}
                cnpj={formatCNPJ(companyData?.cnpj)}
                inicioLivre={companyData?.start_acl}
                logo={merxLogo}
                parterInfosTotal={[""]}
              />
            </ClientNameInfo>

            {userData ? (
              profiles &&
              profiles.length > 0 && (
                <>
                  {userData && <CustoEconomia companyData={companyData} />}
                  {profiles && (
                    <ConsumeLine monthlyData={profiles} profiles={profiles} />
                  )}
                </>
              )
            ) : (
              <div className="right-text-center">
                <h1>
                  Histórico e gráficos serão disponibilizados após a migração
                  ser concluída
                </h1>
              </div>
            )}
            {/* {prevData && <ProjecaoConsumo prevData={prevData} />} */}
            {/* {prevData && <XPTO prevData={prevData} />} */}
            {/* {monthlyData && <Historico monthlyData={monthlyData} />} */}
          </>
        )}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Edite as suas informações</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "8px" }}>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nome de exibição</Form.Label>
              <Form.Control
                type="text"
                placeholder={`${userData?.name}`}
                autoFocus
                disabled
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder={`${userData?.email}`}
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Nova senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="••••••"
                autoFocus
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label>Confirme sua nova senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="••••••"
                autoFocus
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={saveInfo} disabled={disabled}>
            Salvar informações
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </Main>
  );
};

export default ClientDashboard;
