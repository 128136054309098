import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import "../DadosReceita/style.css";

const ChartContainer = styled.div`
  width: 100%;
  margin: auto;
  text-align: left;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;

  padding: 0;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 35px;
`;

const PrevDataContainer = styled.div`
  width: 25%;
  text-align: center;
  margin-top: 1em;
`;

const Medias = ({ uf, consumo }) => {
  const [consumoMedio, setConsumoMedio] = useState(0);
  const [flexMax, setFlexMax] = useState(0);
  const [flexMin, setFlexMin] = useState(0);
  const [usina, setusina] = useState(0);

  useEffect(() => {
    const consumoTotal = consumo.reduce((s, a) => s + a.consumes, 0),
      nonNullConsumo = consumo.filter((c) => c.consumes),
      consumoValues = nonNullConsumo.map((c) => {
        return c.consumes;
      }),
      removalValues = [Math.max(...consumoValues), Math.min(...consumoValues)],
      trimmedConsumoValues = consumoValues.filter(
        (i) => !removalValues.includes(i)
      );
    let consumoMedio = consumoTotal / nonNullConsumo.length,
      US = 0;

    switch (uf) {
      case "AC":
        US = consumoMedio / (0.165 * 730);
        break;
      case "AL":
        US = consumoMedio / (0.18 * 730);
        break;
      case "AP":
        US = consumoMedio / (0.15 * 730);
        break;
      case "AM":
        US = consumoMedio / (0.15 * 730);
        break;
      case "BA":
        US = consumoMedio / (0.18 * 730);
        break;
      case "CE":
        US = consumoMedio / (0.185 * 730);
        break;
      case "DF":
        US = consumoMedio / (0.165 * 730);
        break;
      case "ES":
        US = consumoMedio / (0.165 * 730);
        break;
      case "GO":
        US = consumoMedio / (0.165 * 730);
        break;
      case "MA":
        US = consumoMedio / (0.15 * 730);
        break;
      case "MT":
        US = consumoMedio / (0.17 * 730);
        break;
      case "MS":
        US = consumoMedio / (0.165 * 730);
        break;
      case "MG":
        US = consumoMedio / (0.175 * 730);
        break;
      case "PA":
        US = consumoMedio / (0.15 * 730);
        break;
      case "PB":
        US = consumoMedio / (0.18 * 730);
        break;
      case "PR":
        US = consumoMedio / (0.165 * 730);
        break;
      case "PE":
        US = consumoMedio / (0.18 * 730);
        break;
      case "PI":
        US = consumoMedio / (0.17 * 730);
        break;
      case "RJ":
        US = consumoMedio / (0.18 * 730);
        break;
      case "RN":
        US = consumoMedio / (0.175 * 730);
        break;
      case "RS":
        US = consumoMedio / (0.165 * 730);
        break;
      case "RO":
        US = consumoMedio / (0.155 * 730);
        break;
      case "RR":
        US = consumoMedio / (0.15 * 730);
        break;
      case "SC":
        US = consumoMedio / (0.16 * 730);
        break;
      case "SP":
        US = consumoMedio / (0.165 * 730);
        break;
      case "SE":
        US = consumoMedio / (0.18 * 730);
        break;
      case "TO":
        US = consumoMedio / (0.156 * 730);
        break;
      default:
        US = consumoMedio / (0.165 * 730);
        break;
    }

    setusina(US);
    setFlexMax(Math.max(...trimmedConsumoValues) / consumoMedio - 1);
    setFlexMin(Math.min(...trimmedConsumoValues) / consumoMedio - 1);
    setConsumoMedio(consumoMedio);
  }, [consumo, uf]);

  return (
    <ChartContainer>
      <div
        className="container-dadosReceita"
        style={{ margin: "0", width: "100%" }}
      >
        <div className="container-dadosReceita-title">
          <div className="container-icons-dadosReceita">
            <div className="item-container-dadosReceita">
              <FontAwesomeIcon icon={faChartLine} />
            </div>

            <p className="dadosReceita-title">Previsões</p>
          </div>
        </div>

        <div className="linha-separadora" style={{}} />
        <div className="" style={{ display: "flex" }}>
          <PrevDataContainer className="" display={{ width: "100%" }}>
            <p className="medis-item-1">Volume Médio:</p>
            <p className="medis-item-2">
              {consumoMedio ? consumoMedio.toFixed(3) + "MWh" : "Indisponível"}
            </p>
          </PrevDataContainer>
          <PrevDataContainer className="">
            <p className="medis-item-1">Flex Max:</p>

            <p className="medis-item-2">
              {flexMax > 0
                ? (flexMax * 100).toLocaleString("pt-BR", {
                    style: "decimal",
                  }) + "%"
                : "Indisponível"}
            </p>
          </PrevDataContainer>
          <PrevDataContainer className="">
            <p className="medis-item-1">Flex Min:</p>

            <p className="medis-item-2">
              {flexMin < 0
                ? (flexMin * 100).toLocaleString("pt-BR", {
                    style: "decimal",
                  }) + "%"
                : "Indisponível"}
            </p>
          </PrevDataContainer>
          <PrevDataContainer className="">
            <p className="medis-item-1">Usina Solar:</p>
            <p className="medis-item-2">
              {usina ? usina.toFixed(2) + "MWp" : "Indisponível"}
            </p>
          </PrevDataContainer>
        </div>
      </div>
    </ChartContainer>
  );
};

export default Medias;
