import React, { createContext, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { getUserId } from "../Services/auth";
import faradayApi from "../Services/faradayApi";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const id = getUserId();
      if (!id) {
        setLoading(false);
        return;
      }

      try {
        const response = await faradayApi.get(`users/${id}`);

        if (response.data) {
          const permissions = response.data.permissions
            ? response.data.permissions.split(",")
            : [];

          const userData = {
            id,
            role: response.data.role,
            permissions,
          };

          setUser(userData);
          localStorage.setItem("user", JSON.stringify(userData));
        }
      } catch (error) {
        console.error("Erro ao buscar usuário", error);
        setUser(null);
        localStorage.removeItem("user");
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const hasPermission = (permission) =>
    user?.permissions?.includes(permission) ||
    user?.role === "ADMIN" ||
    user?.role === "SUPER_ADMIN";

  const hasAccess = (requiredRole) => user?.role === requiredRole;

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    history.push("/login");
  };

  return (
    <AuthContext.Provider
      value={{ user, loading, hasPermission, logout, hasAccess }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
