import React, { useEffect, useState } from "react";
import "./style.css";

import { isAuthenticated, logout, getUserId } from "../../Services/auth";

import Logo from "../../Assets/logoMerxPrime.png";
import MenuIcon from "../../Assets/menuIcon.svg";
import CloseMenuIcon from "../../Assets/closeMenuIcon.svg";
import ArrowMenu from "../../Assets/arrowMenu.svg";

import faradayApi from "../../Services/faradayApi";
import { useAuth } from "../../utils/AuthContext";
import { erroToast } from "../../utils/toast";

const Navbar = ({ changeColor = "true" }) => {
  const onLogoutClick = () => {
    logout();
    window.location.href = "/";
  };

  const [role, setRole] = useState(useAuth().user?.role);
  const fetchUserData = async () => {
    if (isAuthenticated()) {
      const id = getUserId();
      const response = await faradayApi.get(`users/${id}`);

      setRole(response.data.role);
    } else {
      logout();
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);
  //On click scrool to bottom bar

  const [isMenuOpen, setIsMenuOpen] = useState();
  const changeMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div
      className="totalDiv"
      id="navbar"
      style={
        changeColor === "true" ? { backgroundColor: "#3271cf" } : undefined
      }
    >
      <nav collapseOnSelect expand="lg" className="bg-blue" variant="light">
        <div className="totalNavbar">
          <div className="responsiveMenu">
            <img src={Logo} alt="Logo da merx" />
            <img
              src={MenuIcon}
              alt="Icon to open menu"
              className="menuAndCloseIcon"
              onClick={changeMenuOpen}
            />
          </div>
          <ul className={`navItens menuOpen${isMenuOpen}`}>
            <div className="logoAndClose">
              <a href="/" className="logo">
                <img src={Logo} alt="Logo da merx" />
              </a>
              <img
                src={CloseMenuIcon}
                alt="Icon to open menu"
                className="closeMenuIcon"
                onClick={changeMenuOpen}
              />
            </div>

            <ul className="middleItens">
              <li>
                <a
                  id="navItem-color"
                  className=""
                  href="/pesquisa-mercado-livre"
                  onClick={changeMenuOpen}
                >
                  Pesquisa
                </a>
                <img src={ArrowMenu} alt="Arrow" className="arrowMenu" />
              </li>

              <li>
                <a
                  id="navItem-color"
                  className=""
                  href="/simulacao"
                  onClick={changeMenuOpen}
                >
                  Simule
                </a>
                <img src={ArrowMenu} alt="Arrow" className="arrowMenu" />
              </li>
              <li>
                <a id="navItem-color" className="" href="/#projects">
                  Projetos
                </a>
                <img
                  src={ArrowMenu}
                  alt="Arrow"
                  className="arrowMenu"
                  onClick={changeMenuOpen}
                />
              </li>
              <li>
                <a
                  id="navItem-color"
                  className="prime"
                  href="/#partner"
                  onClick={changeMenuOpen}
                >
                  Prime
                </a>
                <img src={ArrowMenu} alt="Arrow" className="arrowMenu" />
              </li>
              <li>
                <a
                  id="navItem-color"
                  className=""
                  href="/#whatIsMerx"
                  onClick={changeMenuOpen}
                >
                  Soluções
                </a>
                <img src={ArrowMenu} alt="Arrow" className="arrowMenu" />
              </li>
            </ul>
            {/* {isAuthenticated() && (
                <a
                  id="navItem-color"
                  
                  className=""
                  href="/marketplace"
                >
                  Marketplace
                </a>
              )} */}

            {/*  <li>
              <a id="navItem-color" className="" href="/mercado-livre">
                Mercado Livre de Energia
              </a>
            </li> */}

            {/* <a
                id="navItem-color"
                
                className=""
                href="parceiros"
              >
                Parceiro
              </a> */}

            {/*  <li>
              <a id="navItem-color" className="" href="/autoproducao">
                Autoprodução de Energia
              </a>
            </li> */}

            <ul className="talkAndLogin">
              <li>
                <a
                  id="navItem-color"
                  className="talkWithUs"
                  onClick={changeMenuOpen}
                  href="https://wa.me/558182136133?text=Tenho%20d%C3%BAvidas%20sobre%20a%20Merx"
                >
                  Fale Conosco
                </a>
              </li>

              {!isAuthenticated() && (
                <li className="loginLi">
                  <a
                    id="navItem-color"
                    className="loginButton"
                    href="/login"
                    onClick={changeMenuOpen}
                  >
                    Login
                  </a>
                </li>
              )}

              {isAuthenticated() && (
                <>
                  <li>
                    <a
                      className="clientArea"
                      id="clientArea"
                      onClick={changeMenuOpen}
                      href={
                        role === "CLIENTE"
                          ? "/client"
                          : role === "PARCEIRO"
                          ? "/parceiro"
                          : (role === "ADMIN" || role === "SUPER_ADMIN") &&
                            "/areaAdm"
                      }
                    >
                      {role === "CLIENTE"
                        ? "Área do Cliente"
                        : role === "PARCEIRO"
                        ? "Área do Parceiro"
                        : (role === "ADMIN" || role === "SUPER_ADMIN") &&
                          "Área do Admin"}
                    </a>
                  </li>
                  <li onClick={onLogoutClick} className="loginLi">
                    <p className="loginButton">Sair</p>
                  </li>
                </>
              )}
            </ul>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
