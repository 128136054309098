import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import Navbar from "../Navbar";
import { AnimatePresence, motion } from "framer-motion";

export default function Header() {
  const texts = [
    <>
      Autoprodução é poder, <br />
      economia e sustentabilidade. <br />
      Transforme energia em <br />
      lucro!
    </>,

    <>
      Liberdade de escolha, <br />
      economia em ação. <br />
      No mercado livre de energia, <br />
      você decide e prospera!
    </>,

    <>
      Seja nosso parceiro! <br />
      Juntos, potencializamos <br />
      oportunidades para <br />
      impulsionar seu negócio!
    </>,
  ];

  const variants = {
    enter: {
      x: "100%",
      opacity: 0,
      transition: {
        duration: 0.6,
      },
    },
    center: {
      x: "0%",
      opacity: 1,
      transition: {
        duration: 0.6,
      },
    },
    exit: {
      x: "-100%",
      opacity: 0,
      transition: {
        duration: 0.6,
      },
    },
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const DURATION = 6000;
  const TICK_INTERVAL = 100;

  useEffect(() => {
    let elapsed = 0;
    setProgress(0);

    const intervalId = setInterval(() => {
      elapsed += TICK_INTERVAL;
      let fraction = (elapsed / DURATION) * 100;

      if (fraction >= 100) {
        fraction = 100;
        clearInterval(intervalId);
        setCurrentIndex((prev) => (prev + 1) % texts.length);
      }

      setProgress(fraction);
    }, TICK_INTERVAL);

    return () => clearInterval(intervalId);
  }, [currentIndex, texts.length]);

  function handleBulletClick(index) {
    setCurrentIndex(index);
  }

  return (
    <div className={styles.banner}>
      <Navbar changeColor="false" />
      <div className={styles.bannerText}>
        <div className={styles.bannerTextInfos}>
          <div className={styles.textContainer}>
            <AnimatePresence mode="wait">
              <motion.h2
                key={currentIndex}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                style={{ position: "absolute" }}
              >
                {texts[currentIndex]}
              </motion.h2>
            </AnimatePresence>
          </div>

          <a className={styles.buttonKnow} href="/#whatWeOffer">
            Conheça nossas soluções
          </a>

          <div className={styles.bulletsContainer}>
            {texts.map((_, i) => {
              let bulletProgress = 0;

              if (i < currentIndex) {
                bulletProgress = 100;
              } else if (i === currentIndex) {
                bulletProgress = progress;
              } else {
                bulletProgress = 0;
              }

              return (
                <div
                  key={i}
                  className={styles.bullet}
                  onClick={() => handleBulletClick(i)}
                >
                  <div className={styles.bulletBar}>
                    <div
                      className={styles.bulletFill}
                      style={{ width: `${bulletProgress}%` }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
