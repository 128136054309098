import React, { Component, useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import useHighchartsDefault from "../../Hooks/useHighchartsDefault";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { shortMesesExt } from "../../utils/utilityFunctions";

const ChartContainer = styled.div`
  margin: auto;
  text-align: left;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  padding-left: 0;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 35px;
`;

const LastroChart = ({
  lastro,
}: {
  lastro: { loggedAt: string; consumes: number; generations: number; purchasecontracts: number; salescontracts: number; lastro: number }[];
}) => {
  useHighchartsDefault(Highcharts);
  const [data, setData] = useState<number[]>([]);
  useEffect(() => {
    // setFirstMonth(new Date(lasto[0].loggedAt));
    setData(lastro.map((c) => c.lastro).slice(-12));
  }, [lastro]);

  const months = lastro.slice(-12).map((e) => {
    const date = new Date(e.loggedAt);
    return shortMesesExt[date.getMonth()] + " " + date.getFullYear();
  });

  Highcharts.setOptions({
    lang: {
      decimalPoint: ",",
      thousandsSep: ".",
    },
  });

  const lastroChartOptions = {
    chart: {
      zoomType: "x",
      type: "column",
    },

    title: {
      text: "Lastro",
    },

    subtitle: {
      text: "Fonte: CCEE",
    },

    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },

    credits: {
      enabled: false,
    },

    xAxis: {
      categories: months,
    },

    yAxis: {
      title: {
        text: "Lastro (MWh)",
      },
      labels: {
        format: "{value} MWh",
      },
    },

    tooltip: {
      formatter: function (
        this: Highcharts.TooltipFormatterContextObject
      ): string {
        return `${this.x}<br/><b>${this.y.toFixed(2)}MWh</b>`;
      },
    },

    series: [
      {
        showInLegend: false,
        data: data,
        label: { enabled: true },
        zones: [
          {
            value: 0,
            color: "#f91010",
          },
          {
            color: "#1fa824",
          },
        ],
      },
    ],
  };
  return (
    <ChartContainer className="container-dadosReceita-master">
      <div
        className="container-dadosReceita"
        style={{ marginTop: "0", width: "100%" }}
      >
        <div
          className="container-icons-dadosReceita"
          style={{ justifyContent: "flex-start" }}
        >
          <div className="item-container-dadosReceita">
            <FontAwesomeIcon icon={faChartBar} />
          </div>

          <p className="dadosReceita-title">Lastro</p>
        </div>
        <div className="linha-separadora" />
        <HighchartsReact highcharts={Highcharts} options={lastroChartOptions} />
      </div>
    </ChartContainer>
  );
};

export default LastroChart;
