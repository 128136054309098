import React from "react";
import styles from "./style.module.css";
import Image from "../../Assets/whatWeOfferPartner.svg";
import Image1 from "../../Assets/whatWeOfferFreeMarket.svg";
import Image2 from "../../Assets/whatWeOfferSelfProduction.svg";
import Image3 from "../../Assets/whatWeOfferProjects.svg";
import Image4 from "../../Assets/whatWeOfferConsult.svg";
export default function About() {
  return (
    <section className={styles.section} id="whatIsMerx">
      <div className={styles.totalContent}>
        <div className={styles.titleAndLogo}>
          <h2 id="whatWeOffer">O Que a Merx Oferece?</h2>
        </div>
        <div className={styles.whatMerxOffer}>
          <div className={styles.parcerias}>
            <h3>Parcerias</h3>
            <p className={styles.textOffer}>
              Para resultados eficazes e comissão recorrente.
            </p>
            <a href="https://wa.me/5581982136133?text=Quero%20contratar%20a%20Merx!">
              <button>Explore</button>
            </a>
            <img src={Image} alt="imagem de um foguete ilustrativo" />
          </div>

          <div className={styles.autoproducao}>
            <h3>Autoprodução</h3>
            <p className={styles.textOffer}>
              A autoprodução de energia é a solução ideal para empresas que
              buscam independência energética, economia e sustentabilidade. Com
              a possibilidade de gerar sua própria energia a partir de fontes
              renováveis.
            </p>
            <a href="https://wa.me/5581982136133?text=Quero%20contratar%20a%20Merx!">
              <button>Explore</button>
            </a>

            <img src={Image2} alt="imagem de um foguete ilustrativo" />
          </div>
          <div className={styles.projetos}>
            <h3>Projetos</h3>
            <p className={styles.textOffer}>
              Para tirar seus projetos do papel e se tornar realidade.
            </p>
            <a href="https://wa.me/5581982136133?text=Quero%20contratar%20a%20Merx!">
              <button>Explore</button>
            </a>
            <img src={Image3} alt="imagem de um foguete ilustrativo" />
          </div>
          <div className={styles.mercadoLivre}>
            <h3>Mercado Livre</h3>
            <p className={styles.textOffer}>
              Entrar no Mercado Livre de Energia é a oportunidade perfeita para
              empresas que desejam reduzir custos, ganhar autonomia em sua
              gestão energética.
            </p>
            <a href="https://wa.me/5581982136133?text=Quero%20contratar%20a%20Merx!">
              <button>Explore</button>
            </a>
            <img src={Image1} alt="imagem de um foguete ilustrativo" />
          </div>
          <div className={styles.consultoria}>
            <h3>Consultoria</h3>
            <p className={styles.textOffer}>
              Para transformar seus projetos em realidade no mercado de energia,
              contar com uma consultoria especializada é o primeiro passo rumo
              ao sucesso.
            </p>
            <a href="https://wa.me/5581982136133?text=Quero%20contratar%20a%20Merx!">
              <button>Explore</button>
            </a>
            <img src={Image4} alt="imagem de um foguete ilustrativo" />
          </div>
        </div>
      </div>
    </section>
  );
}
