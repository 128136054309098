import React from "react";
import styles from "./style.module.css";
import imageTest from "../../Assets/imgTestDepoiments.svg";
export default function ManifestMerx() {
  const depoimentos = [
    {
      image: imageTest,
      name: "Thiago TiAgo",
      depoiment: "Lorem lorem lorem lorem lorem lorem lorem lorem ",
    },
    {
      image: imageTest,
      name: "Thiago TiAgo",
      depoiment: "Lorem lorem lorem lorem lorem lorem lorem lorem ",
    },
    {
      image: imageTest,
      name: "Thiago TiAgo",
      depoiment: "Lorem lorem lorem lorem lorem lorem lorem lorem ",
    },
    {
      image: imageTest,
      name: "Thiago TiAgo",
      depoiment: "Lorem lorem lorem lorem lorem lorem lorem lorem ",
    },
    {
      image: imageTest,
      name: "Thiago TiAgo",
      depoiment: "Lorem lorem lorem lorem lorem lorem lorem lorem ",
    },
    {
      image: imageTest,
      name: "Thiago TiAgo",
      depoiment: "Lorem lorem lorem lorem lorem lorem lorem lorem ",
    },
  ];
  return (
    <section className={styles.allContent} id="manifest">
      <div className={styles.content}>
        {/* <div className={styles.divDepoiments}>
          <h2>Depoimento dos clientes</h2>
          <p className={styles.subtitle}>
            Os depoimentos dos nossos clientes refletem o impacto positivo do
            nosso trabalho e a confiança que construímos ao longo dos anos.
          </p>
          <div className={styles.depoiments}>
            {depoimentos.map((depoimento, index) => (
              <div className={styles.depoiment}>
                <img
                  src={depoimento.image}
                  alt="Imagem do responsável pelo depoimento"
                />
                <div className={styles.textsDepoiment}>
                  <h3>{depoimento.name}</h3>
                  <p>{depoimento.depoiment}</p>
                </div>
              </div>
            ))}
          </div>
        </div> */}
        <div className={styles.manifest}>
          <div className={styles.texts}>
            {/* <h2>Assista nosso manifesto</h2>
            <p>
              Nosso manifesto é mais do que palavras, é o reflexo da nossa
              essência, valores e propósito no mercado de energia. É uma
              declaração de compromisso com a inovação, sustentabilidade e
              transformação, mostrando como trabalhamos para criar um futuro
              mais verde, inclusivo e eficiente.
            </p> */}
            <h2>Dê o play e conheça um pouco dos nossos projetos!</h2>
            <a
              href="https://wa.me/5581982136133?text=Quero%20contratar%20a%20Merx!"
              className={styles.knowMore}
            >
              Saiba mais
            </a>
          </div>
          <div className={styles.video}>
            <video
              className={styles.videoToWatch}
              autoPlay={false}
              muted={false}
              loop
              playsInline
              preload="auto"
              controls
              poster="/usina-thumb.jpg"
            >
              <source src="/video-usina.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </section>
  );
}
