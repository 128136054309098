import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import ImageSelfProduction from "../../Assets/imgSelfProductionBenefits.png";
import usinaIeadpeTiuma from "../../Assets/usina-ieadpe-tiuma.jpg";
import usinaEplast from "../../Assets/usina-eplast2.png";
import usinaFortlev from "../../Assets/usina-fortlev.jpg";
import { AnimatePresence, motion } from "framer-motion";

export default function Compare() {
  const ourProjects = [
    {
      image: usinaFortlev,
      place: "1180,85 kWp",
      type: 1,
    },
    {
      image: ImageSelfProduction,
      place: "1274,12 kWp",
      type: 2,
    },
    {
      image: usinaEplast,
      place: "1520,90 kWp",
      type: 1,
    },
    {
      image: usinaIeadpeTiuma,
      place: "700 kWp",
      type: 1,
    },
  ];

  const variants = {
    enter: {
      x: "100%",
      opacity: 0,
      transition: {
        duration: 0.6,
      },
    },
    center: {
      x: "0%",
      opacity: 1,
      transition: {
        duration: 0.6,
      },
    },
    exit: {
      x: "-100%",
      opacity: 0,
      transition: {
        duration: 0.6,
      },
    },
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const DURATION = 6000;
  const TICK_INTERVAL = 100;

  useEffect(() => {
    let elapsed = 0;
    setProgress(0);

    const intervalId = setInterval(() => {
      elapsed += TICK_INTERVAL;
      let fraction = (elapsed / DURATION) * 100;

      if (fraction >= 100) {
        fraction = 100;
        clearInterval(intervalId);
        setCurrentIndex((prev) => (prev + 1) % ourProjects.length);
      }

      setProgress(fraction);
    }, TICK_INTERVAL);

    return () => clearInterval(intervalId);
  }, [currentIndex, ourProjects.length]);

  function handleBulletClick(index) {
    setCurrentIndex(index);
  }

  return (
    <div className={styles.totalInfo} id="projects">
      <div className={styles.sectionCarousel}>
        <h2 className={styles.title}>Nossos projetos</h2>

        <div className={styles.bannerText}>
          <div className={styles.bannerTextInfos}>
            <AnimatePresence mode="wait">
              <motion.div
                key={currentIndex}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                style={{
                  position: "absolute",
                  inset: "0",
                  margin: "auto",
                }}
              >
                <img
                  className={
                    styles[`img-carousel-${ourProjects[currentIndex].type}`]
                  }
                  alt="Imagem do projeto"
                  src={ourProjects[currentIndex].image}
                />
              </motion.div>
            </AnimatePresence>
          </div>
        </div>

        <div className={styles.bulletsContainer}>
          <h2 className={styles.captionCarousel}>
            {ourProjects[currentIndex].place}
          </h2>

          <div className={styles.bulletsDiv}>
            {ourProjects.map((_, i) => {
              let bulletProgress = 0;

              if (i < currentIndex) {
                bulletProgress = 100;
              } else if (i === currentIndex) {
                bulletProgress = progress;
              } else {
                bulletProgress = 0;
              }

              return (
                <div
                  key={i}
                  className={styles.bullet}
                  onClick={() => handleBulletClick(i)}
                >
                  <div className={styles.bulletBar}>
                    <div
                      className={styles.bulletFill}
                      style={{ width: `${bulletProgress}%` }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
