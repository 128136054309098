import React from "react";

const GDXAPETable = ({
  gdData,
  apeData,
  economia,
}: {
  gdData: {
    bandeiras: {
      custoCativoAmarela: number;
      custoCativoVermelhaP1: number;
      custoCativoVermelhaP2: number;
    };
    gerador: {
      consumoGerador: number;
      custoDiesel: number;
      custoGerador: number;
      custoTotalGerador: number;
    };
    energiaInjetada: {
      energiaInjetadaForaPonta: number;
      energiaInjetadaPonta: number;
      custoTEFPInjetada: number;
      custoTEPInjetada: number;
      custoTUSDFPInjetada: number;
      custoTUSDPInjetada: number;
    };
    demandaForaPonta: number;
    parcelaDemandaForaPonta: number;
    demandaPonta: number;
    parcelaDemandaPonta: number;
    demandaForaPontaUltrapassagem: number;
    parcelaDemandaForaPontaUltrapassagem: number;
    demandaPontaUltrapassagem: number;
    parcelaDemandaPontaUltrapassagem: number;
    demandaUsina: number;
    parcelaDemandaUsina: number;
    consumoPonta: number;
    parcelaConsumoPonta: number;
    parcelaTUSDPonta: number;
    parcelaTEPonta: number;
    consumoForaPonta: number;
    parcelaConsumoForaPonta: number;
    parcelaTUSDForaPonta: number;
    parcelaTEForaPonta: number;
    custoConexao: number;
    custoEnergia: number;
    custoTotal: number;
  };
  apeData: {
    demandaForaPonta: number;
    demandaPonta: number;
    consumoForaPonta: number;
    consumoPonta: number;
    consumoAGPFP: number;
    consumoAGPP: number;
    demandaUsina: number;
    parcelaDemandaForaPonta: number;
    parcelaDemandaPonta: number;
    parcelaConsumoForaPonta: number;
    parcelaConsumoPonta: number;
    parcelaDemandaUsina: number;
    parcelaConsumoForaPontaAGP: number;
    parcelaConsumoPontaAGP: number;
    energiaFaturavel: number;
    energiaExcedente: number;
    custoBeneficioTarifario: number;
    taxCovid: number;
    taxScarcity: number;
    encargoSetorial: number;
    gestao: number;
    encargos: number;
    custoEnergiaComplementar: number;
    custoEnergiaExcedente: number;
    custoConexao: number;
    custoEnergia: number;
    custoTotal: number;
  };
  economia: {
    dadosApeXml: {
      ano: string;
      ape: number;
      APE: number;
      economiaAnual: number;
      economiaMensal: number;
      economiaPct: number;
    }[];
    payBackAnual: number;
    payBackMensal: number;
    tir: number;
    vplAnual: number[];
  };
}) => {
  return (
    <>
      <div className="containerResSimulMaster">
        <div className="containerResSimul">
          <p className="containerResSimulTitle">Consumidor GD</p>
          <div className="ResSimulTableTitle">
            <p className="ResSimulTable" style={{ textAlign: "left" }}>
              Grandeza Elétrica
            </p>
            <p className="ResSimulTable">Grandezas Faturadas</p>
            <p className="ResSimulTable">Faturado</p>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Contratada FP
            </p>
            <p className="ResSimulTableContentC">
              {gdData.demandaForaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {gdData.parcelaDemandaForaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Contratada P
            </p>
            <p className="ResSimulTableContentC">
              {gdData.demandaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {gdData.parcelaDemandaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Ultrapassagem FP
            </p>
            <p className="ResSimulTableContentC">
              {gdData.demandaForaPontaUltrapassagem.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {gdData.parcelaDemandaForaPontaUltrapassagem.toLocaleString(
                  "pt-BR",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Ultrapassagem P
            </p>
            <p className="ResSimulTableContentC">
              {gdData.demandaPontaUltrapassagem.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {gdData.parcelaDemandaPontaUltrapassagem.toLocaleString(
                  "pt-BR",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Usina
            </p>
            <p className="ResSimulTableContentC">
              {gdData.demandaUsina.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {gdData.parcelaDemandaUsina.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD FP
            </p>
            <p className="ResSimulTableContentC">
              {gdData.consumoForaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {gdData.parcelaTUSDForaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD P
            </p>
            <p className="ResSimulTableContentC">
              {gdData.consumoPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {gdData.parcelaTUSDPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TE FP
            </p>
            <p className="ResSimulTableContentC">
              {gdData.consumoForaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {gdData.parcelaTEForaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TE P
            </p>
            <p className="ResSimulTableContentC">
              {gdData.consumoPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {gdData.parcelaTEPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Energia injetada TUSD FP
            </p>
            <p className="ResSimulTableContentC">
              {gdData.energiaInjetada.energiaInjetadaForaPonta.toLocaleString(
                "pt-BR",
                {
                  style: "decimal",
                }
              )}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {gdData.energiaInjetada.custoTUSDFPInjetada.toLocaleString(
                  "pt-BR",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Energia injetada TUSD P
            </p>
            <p className="ResSimulTableContentC">
              {gdData.energiaInjetada.energiaInjetadaPonta.toLocaleString(
                "pt-BR",
                {
                  style: "decimal",
                }
              )}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {gdData.energiaInjetada.custoTUSDPInjetada.toLocaleString(
                  "pt-BR",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Energia injetada TE FP
            </p>
            <p className="ResSimulTableContentC">
              {gdData.energiaInjetada.energiaInjetadaForaPonta.toLocaleString(
                "pt-BR",
                {
                  style: "decimal",
                }
              )}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {gdData.energiaInjetada.custoTEFPInjetada.toLocaleString(
                  "pt-BR",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Energia injetada TE P
            </p>
            <p className="ResSimulTableContentC">
              {gdData.energiaInjetada.energiaInjetadaPonta.toLocaleString(
                "pt-BR",
                {
                  style: "decimal",
                }
              )}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {gdData.energiaInjetada.custoTEPInjetada.toLocaleString(
                  "pt-BR",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Gerador
            </p>
            <p className="ResSimulTableContentC">
              {gdData.gerador.consumoGerador.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {gdData.gerador.custoTotalGerador.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <hr />

          <div className="ResSimulTableTitle">
            <p
              className="ResSimulTableContent"
              style={{
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Total
            </p>
            <p className="ResSimulTableContent"></p>
            <div className="separarNum">
              <p
                className="ResSimulTableContentB"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {gdData.custoTotal.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>
        </div>

        <div className="containerResSimul">
          <p className="containerResSimulTitle">Autoprodutor de energia</p>
          <div className="ResSimulTableTitle">
            <p className="ResSimulTable" style={{ textAlign: "left" }}>
              Grandeza Elétrica
            </p>
            <p className="ResSimulTable">Grandezas Faturadas</p>
            <p className="ResSimulTable">Faturado</p>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Contratada FP
            </p>
            <p className="ResSimulTableContentC">
              {apeData.demandaForaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.parcelaDemandaForaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Contratada P
            </p>
            <p className="ResSimulTableContentC">
              {apeData.demandaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.parcelaDemandaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda usina
            </p>
            <p className="ResSimulTableContentC">
              {apeData.demandaUsina.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.parcelaDemandaUsina.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD FP
            </p>
            <p className="ResSimulTableContentC">
              {apeData.consumoForaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.parcelaConsumoForaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD P
            </p>
            <p className="ResSimulTableContentC">
              {apeData.consumoPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.parcelaConsumoPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD FP APE
            </p>
            <p className="ResSimulTableContentC">
              {apeData.consumoAGPFP.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.parcelaConsumoForaPontaAGP.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD P APE
            </p>
            <p className="ResSimulTableContentC">
              {apeData.consumoAGPP.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.parcelaConsumoPontaAGP.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Energia complementar
            </p>
            <p className="ResSimulTableContentC">
              {apeData.energiaFaturavel.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.custoEnergiaComplementar.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Energia excedente
            </p>
            <p className="ResSimulTableContentC">
              {apeData.energiaExcedente.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.custoEnergiaExcedente.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Benefício tarifário
            </p>
            <p
              className="ResSimulTableContentC"
              style={{ textAlign: "center" }}
            >
              -
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.custoBeneficioTarifario.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Encargos
            </p>
            <p
              className="ResSimulTableContentC"
              style={{ textAlign: "center" }}
            >
              -
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.encargos.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Custo Operacional e CCEE
            </p>
            <p
              className="ResSimulTableContentC"
              style={{ textAlign: "center" }}
            >
              -
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.gestao.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p
              className="ResSimulTableContent"
              style={{ textAlign: "left" }}
            ></p>
            <p
              className="ResSimulTableContentD"
              style={{ textAlign: "center", lineHeight: "34px" }}
            ></p>
            <div className="separarNum">
              <p className="ResSimulTableContentB"></p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p
              className="ResSimulTableContent"
              style={{ textAlign: "left" }}
            ></p>
            <p
              className="ResSimulTableContentD"
              style={{ textAlign: "center", lineHeight: "34px" }}
            ></p>
            <div className="separarNum">
              <p className="ResSimulTableContentB"></p>
            </div>
          </div>

          <hr />

          <div className="ResSimulTableTitle">
            <p
              className="ResSimulTableContent"
              style={{
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Total
            </p>
            <p className="ResSimulTableContent"></p>
            <div className="separarNum">
              <p
                className="ResSimulTableContentB"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {apeData.custoTotal.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="resultadoSimulacaoContainer">
        <div className="economia-card">
          <h1>Custo GD</h1>
          <h2>
            {gdData.custoTotal.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </h2>
        </div>

        <div className="economia-card">
          <h1>Custo Autoprodutor</h1>
          <h2>
            {apeData.custoTotal.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </h2>
        </div>

        <div className="economia-card">
          <h1>Economia mensal</h1>
          <div className="economia-value">
            <h2>
              {(gdData.custoTotal - apeData.custoTotal).toLocaleString(
                "pt-BR",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}
            </h2>
            <h3
              className={
                gdData.custoTotal - apeData.custoTotal >= 0
                  ? "economia-positivo"
                  : "economia-negativo"
              }
            >
              {(
                (gdData.custoTotal - apeData.custoTotal) /
                gdData?.custoTotal
              ).toLocaleString("pt-BR", {
                style: "percent",
              })}
            </h3>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "16px" }}>
        <span className="simulacao-form-header">Bandeiras</span>
        <hr />
      </div>

      <div className="resultadoSimulacaoContainer">
        <div className="bandeirasDiv">
          <span className="bandeirasTitle">Custo GD</span>
          <div className="economia-card">
            <h1 style={{ display: "flex", justifyContent: "space-between" }}>
              Verde
              <span style={{ fontWeight: "bold" }}>
                {gdData.custoTotal.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </h1>
            <div className="bandeiraVerde" />
          </div>
        </div>

        <div className="bandeirasDiv">
          <span className="bandeirasTitle">Custo APE</span>
          <div className="economia-card">
            <h1 style={{ display: "flex", justifyContent: "space-between" }}>
              APE
              <span style={{ fontWeight: "bold" }}>
                {apeData.custoTotal.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </h1>
            <div className="bandeiraAzul" />
          </div>
        </div>

        <div className="bandeirasDiv">
          <span className="bandeirasTitle">Economia Mensal</span>
          <div className="economia-card">
            <h1 style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ fontWeight: "bold" }}>
                {(gdData.custoTotal - apeData.custoTotal).toLocaleString(
                  "pt-BR",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}
              </span>
              <span
                style={{ fontWeight: "bold" }}
                className={
                  gdData.custoTotal - apeData.custoTotal >= 0
                    ? "economia-positivo"
                    : "economia-negativo"
                }
              >
                {(
                  (gdData.custoTotal - apeData.custoTotal) /
                  gdData.custoTotal
                ).toLocaleString("pt-BR", {
                  style: "percent",
                })}
              </span>
            </h1>
            <div className="bandeiraCinza" />
          </div>
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            Amarela
            <span style={{ fontWeight: "bold" }}>
              {gdData.bandeiras.custoCativoAmarela.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          </h1>
          <div className="bandeiraAmarela" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            APE
            <span style={{ fontWeight: "bold" }}>
              {apeData.custoTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          </h1>
          <div className="bandeiraAzul" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontWeight: "bold" }}>
              {(
                gdData.bandeiras.custoCativoAmarela - apeData.custoTotal
              ).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
            <span
              style={{ fontWeight: "bold" }}
              className={
                gdData.bandeiras.custoCativoAmarela - apeData.custoTotal >= 0
                  ? "economia-positivo"
                  : "economia-negativo"
              }
            >
              {(
                (gdData.bandeiras.custoCativoAmarela - apeData.custoTotal) /
                gdData.bandeiras.custoCativoAmarela
              ).toLocaleString("pt-BR", {
                style: "percent",
              })}
            </span>
          </h1>
          <div className="bandeiraCinza" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            Vermelha P1
            <span style={{ fontWeight: "bold" }}>
              {gdData.bandeiras.custoCativoVermelhaP1.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          </h1>
          <div className="bandeiraVermelhaP1" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            APE
            <span style={{ fontWeight: "bold" }}>
              {apeData.custoTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          </h1>
          <div className="bandeiraAzul" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontWeight: "bold" }}>
              {(
                gdData.bandeiras.custoCativoVermelhaP1 - apeData.custoTotal
              ).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
            <span
              style={{ fontWeight: "bold" }}
              className={
                gdData.bandeiras.custoCativoVermelhaP1 - apeData.custoTotal >= 0
                  ? "economia-positivo"
                  : "economia-negativo"
              }
            >
              {(
                (gdData.bandeiras.custoCativoVermelhaP1 - apeData.custoTotal) /
                gdData.bandeiras.custoCativoVermelhaP1
              ).toLocaleString("pt-BR", {
                style: "percent",
              })}
            </span>
          </h1>
          <div className="bandeiraCinza" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            Vermelha P2
            <span style={{ fontWeight: "bold" }}>
              {gdData.bandeiras.custoCativoVermelhaP2.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          </h1>
          <div className="bandeiraVermelhaP2" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            APE
            <span style={{ fontWeight: "bold" }}>
              {apeData.custoTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          </h1>
          <div className="bandeiraAzul" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontWeight: "bold" }}>
              {(
                gdData.bandeiras.custoCativoVermelhaP2 - apeData.custoTotal
              ).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
            <span
              style={{ fontWeight: "bold" }}
              className={
                gdData.bandeiras.custoCativoVermelhaP2 - apeData.custoTotal >= 0
                  ? "economia-positivo"
                  : "economia-negativo"
              }
            >
              {(
                (gdData.bandeiras.custoCativoVermelhaP2 - apeData.custoTotal) /
                gdData.bandeiras.custoCativoVermelhaP2
              ).toLocaleString("pt-BR", {
                style: "percent",
              })}
            </span>
          </h1>
          <div className="bandeiraCinza" />
        </div>
      </div>
    </>
  );
};

export default GDXAPETable;
