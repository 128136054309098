import { useState } from "react";
import React from "react";
import "./style.css";
import logoMerxVerde from "../../Assets/logoMerxVerde.svg";
import iconEmail from "../../Assets/iconEmail.svg";
import iconPassword from "../../Assets/icoPassword.svg";
import arrowBackHome from "../../Assets/arrowBackHome.svg";
import faradayApi from "../../Services/faradayApi";
import { getRole, login } from "../../Services/auth";
import { useHistory } from "react-router-dom";
import closedEye from "../../Assets/closedeye.svg";
import openEye from "../../Assets/openEye.svg";
import awaitIcon from "../../Assets/await.svg";
import { toast } from "react-toastify";
import { erroToast, sucessToast } from "../../utils/toast";
import { useNavigate } from "react-router-dom";

export default function Main() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [geralError, setGeralError] = useState("");
  const [hideEye, setHideEye] = useState("");
  const [enableSendEmail, setEnableSendEmail] = useState(false);
  const [swapPosition, setSwapPosition] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailSended, setEmailSended] = useState("");
  const history = useHistory();

  const handleSendEmail = async (e) => {
    setLoading(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      setEmailSended(
        " Se houver uma conta associada ao e-mail informado, você receberá uma mensagem com as instruções para redefinir sua senha em breve. também a sua pasta de spam ou lixo eletrônico."
      );
      setEnableSendEmail(true);
      setLoading(false);
    } else {
      setEmailSended("Digite um email válido");
      setLoading(false);
    }

    try {
      await faradayApi
        .post("auth/lost-password", { email })
        .then((response) => {});
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleChangeForgot = () => {
    setSwapPosition(!swapPosition);
    setPasswordError("");
    setGeralError("");
    setEmailSended("");
    setEnableSendEmail(false);
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!email) {
      setEmailError("Preencha o email!");
      setPasswordError("");
      setGeralError("");
      setLoading(false);
      return;
    }

    if (!password) {
      setPasswordError("Preencha a senha!");
      setEmailError("");
      setGeralError("");
      setLoading(false);
      return;
    }

    try {
      const response = await faradayApi.post("auth/login", { email, password });

      login(
        response.data.accessToken,
        response.data.id,
        response.data.refreshToken,
        response.data.role
      );

      sucessToast("Login efetuado com sucesso!");

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const role = getRole();

      if (role) {
        if (role === "CLIENTE") {
          window.location.href = "/client";
        } else if (role === "PARCEIRO") {
          window.location.href = "/parceiro";
        } else if (role === "ADMIN" || role === "SUPER_ADMIN") {
          window.location.href = "/areaAdm";
        } else {
          window.location.href = "/";
        }
      }
    } catch (error) {
      setEmailError("");
      setPasswordError("");

      if (
        error.response?.data.message === "O acesso da sua conta está expirado!"
      ) {
        toast.error("O acesso da sua conta está expirado!");
      } else {
        toast.error("Credenciais incorretas ou pendentes de validação.");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const showAndHidePassword = () => {
    setHideEye(!hideEye);
  };

  return (
    <div>
      <div className="mainComponent">
        <section className={`ladoEsquerdo ${swapPosition ? "trueChange" : ""}`}>
          <img src={logoMerxVerde}></img>
          <div className="meio">
            <h1>Conecte-se e impulsione seus negócios</h1>
            <p>
              Acesse sua conta e aproveite todas as vantagens de ser um parceiro
              MERX. Faça simulações no Mercado Livre de Energia, pesquise
              oportunidades e receba atualizações sobre os clientes que você
              indicou.
            </p>
          </div>
          <p>
            <b>⭐5.0 Confiança </b>de mais de 1.500 integradores e parceiros.
          </p>
        </section>
        <section className={`ladoDireito`}>
          <img
            src={logoMerxVerde}
            alt="logoMerx"
            className="logoMerxLadoDireito"
          />
          <div className="solicitarLogin">
            <p>Ainda não possui login?</p>
            <button>Solicitar</button>
          </div>
          <div className="login">
            <h3>{swapPosition ? "Recuperação de senha" : "Login"}</h3>
            <p className="insertLogin">
              {swapPosition
                ? "Insira seu email abaixo"
                : "Insira seus dados de login abaixo"}
            </p>

            <section className="loginInputs">
              <label for="email">
                <p>Email</p>
              </label>
              <div className="inputContainer">
                <img src={iconEmail} alt="iconeEmail" className="iconEmail" />
                <input
                  type="email"
                  placeholder="nome@exemplo.com"
                  className="emailInput"
                  value={email}
                  onChange={(e) => handleChangeEmail(e)}
                />
              </div>
              <p className="error">{emailError}</p>
              {!swapPosition && (
                <>
                  {" "}
                  <label for="senha" className="senhaLabel">
                    <p>Senha</p>
                    <p className="forgot" onClick={handleChangeForgot}>
                      Esqueci a senha
                    </p>
                  </label>
                  <div className="inputContainer">
                    <img src={iconPassword} alt="icon password" />
                    <input
                      type={hideEye ? "text" : "password"}
                      placeholder="Sua senha"
                      value={password}
                      onChange={(e) => handleChangePassword(e)}
                    ></input>
                    <img
                      src={openEye}
                      alt="olhosAbertos "
                      className={`eyeIcon enable${hideEye}`}
                      onClick={showAndHidePassword}
                    />
                    <img
                      src={closedEye}
                      alt="olhosFechados"
                      className={`eyeIcon enable${!hideEye}`}
                      onClick={showAndHidePassword}
                    />
                  </div>
                  <p className="error">{passwordError}</p>
                </>
              )}
            </section>
            {geralError && <p className="error errorGeral">{geralError}</p>}
            {emailSended && <p className="emailEnviado">{emailSended}</p>}
            <button
              className={`entrar loading${loading}`}
              onClick={(e) =>
                swapPosition ? handleSendEmail(e) : handleSignIn(e)
              }
              type="submit"
              disabled={loading || (swapPosition && enableSendEmail)}
            >
              {loading ? (
                <img src={awaitIcon} alt="loadingIcon" className="awaitIcon" />
              ) : swapPosition ? (
                "Enviar email"
              ) : (
                "Entrar"
              )}
            </button>

            {swapPosition ? (
              <>
                <span
                  className="backHome"
                  style={{ cursor: "pointer" }}
                  onClick={handleChangeForgot}
                >
                  <img
                    src={arrowBackHome}
                    alt="icone de voltar para home"
                  ></img>
                  <button>Voltar</button>
                </span>
              </>
            ) : (
              <a href="/" className="backHome">
                <img src={arrowBackHome} alt="icone de voltar para home"></img>
                <button>Voltar</button>
              </a>
            )}
          </div>
          <p className="direitos">© 2024 MERX energia. All Rights Reserved.</p>
        </section>
      </div>
    </div>
  );
}
